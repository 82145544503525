import gql from 'graphql-tag';
import { textPairFragmentStr } from '../../../query/fragmentStrings';

export const GET_PASSWORD_STRENGTH = gql`
  query GetPasswordStrengthText {
    textFor {
      passwordStrengthTitle: pair(pairKey: "passwordStrengthTitle") { ...textPair }
      passwordStrengthRequirements: pair(pairKey: "passwordStrengthRequirements") { ...textPair }
      passwordStrengthNotMet: pair(pairKey: "passwordStrengthNotMet") { ...textPair }
      passwordStrengthWeak: pair(pairKey: "passwordStrengthWeak") { ...textPair }
      passwordStrengthGood: pair(pairKey: "passwordStrengthGood") { ...textPair }
      passwordStrengthStrong: pair(pairKey: "passwordStrengthStrong") { ...textPair }
    }
  }

  ${textPairFragmentStr}
`;
