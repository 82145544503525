import PropTypes from 'prop-types';

export const propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired, // Matches DS Component
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  control: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  defaultValue: PropTypes.string,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
};

export const defaultProps = {
  children: undefined,
  isRequired: true, // defaulting to true
  control: null,
  defaultValue: '',
  onChange: undefined,
};
