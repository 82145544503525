import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../../Messages/Messages';
import TmntText from '../Text/TmntText';
import { GET_PASSWORD_STRENGTH } from './operations';
import { evaluateStrength } from './utils';

const propTypes = {
  password: PropTypes.string.isRequired,
};

export default function PasswordStrengthMeter({ password }) {
  const [strength, setStrength] = useState(undefined);
  const { data, loading, error } = useQuery(GET_PASSWORD_STRENGTH);

  useEffect(() => {
    setStrength(evaluateStrength(password));
  }, [password]);

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  const { textFor = {} } = data;

  const getStrengthText = (strengthVal) => {
    switch (strengthVal) {
      case 'STRONG':
        return textFor?.passwordStrengthStrong;
      case 'GOOD':
        return textFor?.passwordStrengthGood;
      case 'WEAK':
        return textFor?.passwordStrengthWeak;
      case 'NOT_MET':
      default:
        return textFor?.passwordStrengthNotMet;
    }
  };

  return (
    <div className="pw-strength" data-pwstrength={strength} data-testid="pw-strength">
      <div className="pw-strength__desc">
        <p>
          <TmntText tmnt={textFor?.passwordStrengthRequirements} />
        </p>
      </div>
      <div className="pw-strength__meter">
        <span className="pw-strength__bar" data-pwstrength-category="NOT_MET" />
        <span className="pw-strength__bar" data-pwstrength-category="WEAK" />
        <span className="pw-strength__bar" data-pwstrength-category="GOOD" />
        <span className="pw-strength__bar" data-pwstrength-category="STRONG" />
      </div>
      {(strength === 'NOT_MET' || strength === 'EMPTY') ? (
        <div className="pw-strength__label">
          <p>
            <TmntText tmnt={getStrengthText(strength)} />
          </p>
        </div>
      ) : (
        <div className="pw-strength__label">
          <p>
            <TmntText tmnt={textFor?.passwordStrengthTitle} />
            :
            <span className="pw-strength__score">
              {' '}
              <TmntText tmnt={getStrengthText(strength)} />
            </span>
          </p>
        </div>
      )}
    </div>
  );
}

PasswordStrengthMeter.propTypes = propTypes;
