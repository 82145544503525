// Base fragment strings
export const textPairFragmentStr = `
  fragment textPair on TextPair {
    key
    value
  }
`;

export const labelValuePairFragmentStr = `
  fragment labelValuePair on LabelValuePair {
    label { ...textPair }
    value
}
`;

// Error Fragment
export const errorFragmentStr = `
  fragment error on Error {
    message {
      key
      value
    }
    status
  }
`;

// Form fragment strings
export const optionValuesFragmentStr = `
  fragment optionValues on Option {
    optionValue
    text {...textPair}
    description {...textPair}
  }
`;

export const inputFragmentStr = `
  fragment input on Input {
    error { ...textPair }
    label { ...textPair }
    name
    description { ...textPair }
    requiredField
  }
`;

export const multiSelectFragmentStr = `
  fragment multiselect on MultiSelect {
    description { ...textPair }
    error { ...textPair }
    label { ...textPair }
    name
    optionList { ...optionValues }
  }
`;

export const formGroupFragmentStr = `
  fragment formGroup on FormGroup {
    label { ...textPair }
    error { ...textPair }
    fieldList {
      ... on MultiSelect { ...multiselect }
      ... on Input { ...input }
    }
  }
`;

export const formFragmentStr = `
  fragment form on Form {
    id
    label { ...textPair }
    error { ...textPair }
    formGroupList { ...formGroup }
  }
`;

export const reviewItemFragmentStr = `
  fragment reviewItem on ReviewItem {
    gender
    name
    color
    size
    productId
    productUrl
    imageSrc
    shortSku
  }
`;

export const linkListValuesStr = `
  fragment linkListValues on FieldOptionList {
    label { ...textPair }
    options {
      url: optionValue
      text {...textPair}
      redirectRoute
      description {...textPair}
    }
  }
`;

export const addressFragmentStr = `
  fragment address on ShippingAddress {
    accessPointName
    address1
    address2
    country
    formattedCityStateZip
    formattedName
    mapUrl { ...labelValuePair }
  }
`;

export const shippingDetailsFragmentStr = `
  fragment shippingDetails on ShippingDetails {
    title { ...textPair }
    shippedToAddress { ...address }
  }
`;

export const billingInfoItemFragmentStr = `
  fragment billingInfoItem on BillingInfoItem {
    paymentIcon
    paymentIconLabel
    cardNumber
    cardExpiration
    paymentType
    email
    paymentURL
    paymentStatus
    billingAddress { ...address }
  }
`;

export const billingInfoFragmentStr = `
  fragment billingInfo on BillingInfo {
    title { ...textPair }
    billingInfoList { ...billingInfoItem }
  }
`;

export const vatDutyDetailsFragment = `
  fragment vatDutyDetails on VatDutyDetails {
    title { ...textPair }
    recipientNotChargedAddlDuties { ...textPair }
    extendedVatDetails {
      vatIncludedInMerchPriceToCountry { ...textPair }
      vatDeductedNonCountryShipments { ...textPair }
      recipientNotLiableForImportDuties { ...textPair }
      vatRefundedForMerhandiseReturns { ...textPair }
    }
    sandhVatNotRefundedMsg { ...textPair }
  }
`;

export const orderDetailsItemFragmentStr = `
  fragment order on OrderDetailItems {
    shippingDetails { ...shippingDetails }
    billingInfo { ...billingInfo }
    vatDutyDetails { ...vatDutyDetails }
  }
`;
