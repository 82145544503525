const countNumbersInString = (passwordVal) => {
  const numberArray = passwordVal.match(/\d/g);
  return numberArray ? numberArray.length : 0;
};

const countLettersInString = (passwordVal) => {
  const letterArray = passwordVal.match(/[a-zA-Z]/g);
  return letterArray ? letterArray.length : 0;
};

export const evaluateStrength = (passwordVal) => {
  if (!passwordVal) return 'EMPTY';

  const hasNumbers = (/\d/.test(passwordVal));
  const hasLowercase = (/[a-z]/.test(passwordVal));
  const hasUppercase = (/[A-Z]/.test(passwordVal));
  const hasSpecialChars = (/[^A-Za-z0-9]/.test(passwordVal));
  const numberCount = countNumbersInString(passwordVal);
  const letterCount = countLettersInString(passwordVal);

  const minReqMet = (
    passwordVal.length >= 8
    && hasNumbers
    && (hasLowercase || hasUppercase)
  );

  const goodReqMet = (
    passwordVal.length >= 9
    && hasNumbers
    && (hasLowercase || hasUppercase)
    && hasSpecialChars
    && numberCount >= 2
    && letterCount >= 2
  );

  const strongReqMet = (
    passwordVal.length >= 14
    && hasNumbers
    && (hasLowercase || hasUppercase)
    && hasSpecialChars
    && numberCount >= 2
    && letterCount >= 2
  );

  if (strongReqMet) return 'STRONG';
  if (goodReqMet) return 'GOOD';
  if (minReqMet) return 'WEAK';

  return 'NOT_MET';
};
