import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { InputField } from 'anf-core-react';
import { propTypes, defaultProps } from './props';
import { usePhoneNumberValidation } from '../../../hooks';

export default function ControlledPhoneNumberField(props) {
  const {
    children,
    control,
    defaultValue,
    id,
    isRequired,
    label,
    name,
  } = props;

  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState(defaultValue);
  const validate = usePhoneNumberValidation();

  const { field, fieldState } = useController({
    control,
    defaultValue,
    name,
    rules: {
      required: isRequired,
      validate: (value) => validate(value).isValid || 'invalid phone number',
    },
  });

  useEffect(() => {
    // libphonenumber-js package adds country codes to the given phone number.
    // We want to display the returned formatted number containing the country code.
    setFormattedPhoneNumber(validate(field.value).formattedPhoneNumber);
  }, [field.value, validate]);

  return (
    <InputField
      autoComplete="tel"
      id={id}
      name={name}
      isInvalid={fieldState.invalid}
      isRequired={isRequired}
      label={label}
      maxLength={25}
      type="tel"
      value={formattedPhoneNumber}
      onChange={field.onChange}
    >
      { children }
    </InputField>
  );
}

ControlledPhoneNumberField.propTypes = propTypes;
ControlledPhoneNumberField.defaultProps = defaultProps;
